enum State {
  ActiveFilters = 'active-filters-state',
  ActiveProductContext = 'active-product-context-state',
  ActiveProductType = 'active-product-type-state',
  ArePreselectedFiltersCustomized = 'are-preselected-filters-customized-state',
  BestSellingShops = 'best-selling-shops-state',
  CurrentFilterGroups = 'current-filter-groups-state',
  CustomerDataFromCookie = 'customer-data-from-cookie-state',
  CustomerPayload = 'customer-payload-state',
  EnabledProductDependentFilters = 'enabled-product-dependent-filters-state',
  FilterScaffold = 'filter-scaffold-state',
  FirstLevelActiveIndex = 'first-level-active-index-state',
  IsBurgerNavigationVisible = 'is-burger-navigation-visible-state',
  IsFiltersListHidden = 'is-filters-list-hidden-state',
  IsFiltersListHiddenMobile = 'is-filters-list-hidden-mobile-state',
  IsModalVisible = 'is-modal-visible-state',
  PreselectedFilters = 'preselected-filters-state',
  ProductData = 'product-data-state',
  Recaptcha = 'recaptcha',
  SecondLevelActiveIndex = 'second-level-active-index-state',
  SelectedDesignType = 'selected-design-type-state',
  SelectedFiltersFlat = 'selected-filters-flat-state',
  SelectedFiltersForQueryString = 'selected-filters-for-query-string-state',
  SelectedProductColor = 'selected-product-color-state',
  SelectedProducts = 'selected-products-state',
  SelectedSecondLevelIndex = 'selected-second-level-index-state',
  SelectedThirdLevelIndex = 'selected-third-level-index-state',
  ShopData = 'shop-data-state',
  Slug = 'slug-state',
  SortedFilters = 'sorted-filters',
  Sorting = 'sorting-state',
  ThirdLevelActiveIndex = 'third-level-active-index-state'
};

export { State };
